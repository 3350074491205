import { AuthenticationContext, adalFetch, withAdalLogin } from 'react-adal';

export const adalConfig = {
  tenant: '2903b32e-b3db-4aad-8b64-f3f7b64e235c',
  clientId: '7fb6ae7e-e90c-45d0-947c-fd46b874017c',
  endpoints: {
    api: '7fb6ae7e-e90c-45d0-947c-fd46b874017c',
  },
  cacheLocation: 'localStorage',
};

export const authContext = new AuthenticationContext(adalConfig);

export const adalApiFetch = (fetch, url, options) =>
  adalFetch(authContext, adalConfig.endpoints.api, fetch, url, options);

export const withAdalLoginApi = withAdalLogin(authContext, adalConfig.endpoints.api);