import React, { Component } from "react";

class App extends Component {
  render() {
    return (
      <>
        <div
          className="container"
          style={{
            textAlign: "center",
            background: "#fff",
            padding: "40px",
            borderRadius: "10px",
          }}
        >
          <h1
            style={{
              textAlignL: "center",
              fontSize: "36px",
              marginBottom: "20px",
            }}
          >
            Service is Currently Down
          </h1>
          <p style={{ textAlign: "center", fontSize: "18px", color: "#666" }}>
            We apologize for the inconvenience. Our team is working hard to
            resolve{" "}
          </p>
        </div>
      </>
    );
  }
}

export default App;
