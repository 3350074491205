export default {
    defaultPath: '/health-dashboard',
    basename: '', // only at build time to set, like /datta-able
    pinotQueryUrl: 'https://2cg767olo9.execute-api.us-west-2.amazonaws.com/prod/pinot/sql',
    pinotGraphQLUrl: 'https://ezwgm5dlrzg45elh6pv332gih4.appsync-api.us-west-2.amazonaws.com/graphql',
    pinotAdapterApiKey:'RBGKHiIhlS3nZc0FzeKoU9p5fuJfkfIL3YOfkDC9',
    pinotAppSyncApiKey:'da2-mgpg3gh5m5cu7cmhlhw62krlqm',
    layout: 'horizontal', // vertical, horizontal
    preLayout: '', // null, layout-2, layout-2-2, layout-3, layout-4, layout-4-2, layout-6, layout-8
    collapseMenu: false, // mini-menu
    layoutType: 'menu-dark', // menu-dark, menu-light, dark
    navIconColor: false,
    headerBackColor: 'header-default', // header-default, header-blue, header-red, header-purple, header-lightblue, header-dark
    navBackColor: 'navbar-default', // navbar-default, navbar-blue, navbar-red, navbar-purple, navbar-lightblue, navbar-dark
    navBrandColor: 'brand-default', // brand-default, brand-blue, brand-red, brand-purple, brand-lightblue, brand-dark
    navBackImage: false, // false, navbar-image-1, navbar-image-2, navbar-image-3, navbar-image-4, navbar-image-5
    rtlLayout: false,
    navFixedLayout: true,
    headerFixedLayout: false,
    boxLayout: false,
    navDropdownIcon: 'style1', // style1, style2, style3
    navListIcon: 'style1', // style1, style2, style3, style4, style5, style6
    navActiveListColor: 'active-default', // active-default, active-blue, active-red, active-purple, active-lightblue, active-dark
    navListTitleColor: 'title-default', // title-default, title-blue, title-red, title-purple, title-lightblue, title-dark
    navListTitleHide: false,
    configBlock: false,
    layout6Background : 'linear-gradient(to right, #A445B2 0%, #D41872 52%, #FF0066 100%)', // used only for pre-layout = layout-6
    layout6BackSize : '', // used only for pre-layout = layout-6
    avlNodeList : ['ALL','914','GCVendorNode','Global','MFI','STORE_TOTAL','101','108','109','110','111','112','113','114','115','116','117','118','119','120','121','122','123','124','125','126','127','128','129','130','131','132','133','134','135','151','152','153','154','155','156','157','181','216','217','218','219','220','221','222','223','224','225','226','227','228','229','230','231','232','233','234','235','236','237','238','241','242','243','244','245','246','251','261','310','311','312','320','321','322','323','324','330','331','332','333','334','335','336','337','338','339','341','342','343','344','345','350','351','352','360','361','362','363','364','365','366','367','370','392','393','394','410','421','422','423','424','425','426','431','432','433','440','441','442','443','444','445','446','447','448','449','450','451','452','453','454','455','456','460','461','462','463','464','465','466','467','468','469','470','471','480','481','489','490','491','492','493','494','495','496','497','498','510','511','512','519','521','522','523','524','525','531','540','550','551','552','553','554','555','556','599','611','612','613','614','615','616','617','618','619','620','621','622','623','624','625','626','631','632','633','710','711','712','713','720','721','722','723','724','726','727','731','732','733','734','735','736','737','738','739','740','741','742','743','744','745','746','750','751','752','753','760','761','762','763','764','765','766','767','769','770','771','772','773','774','775','776','777','778','779','780','781','782','783','784','785','786','787','789','790','791','792','809','810','811','812','814','815','816','817','818','819','821','822','823','824','825','826','827','828','829','830','831','832','833','838','839','841','842','843','844','845','846','847','851','852','853','854','855','856','857','858','860','861','862','863']
};
